import React from 'react';
import Image from 'react-bootstrap/Image'
import social_slider from "../Assets/slider-img/socialslider.gif"

const Socialslider = () => {
  return (
    <>
    <div className="SocialSlider">
        <Image src={social_slider} fluid className="Social Slider" />
    </div>
    </>
  );
}

export default Socialslider;
