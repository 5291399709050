import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Apartment from '../Assets/images/apartment.png';
import Corporate from '../Assets/images/corporate.png';
import Cinema from '../Assets/images/theatre.png';
import Food from '../Assets/images/Meals.png';
import "./css/component.css";
import "./css/style.css";
const Travellingblues = () => {
  return (
    <div className='Travelling'>
      <h1>Beat the travelling blues</h1>
      <div className="Containers">
      <Row>
        <Col lg={3} xs={6} className='travel-card'>
        <img
          className="d-block w-100"
          src={Apartment}
          alt="Apartment"
        />
        <p className='text-white'>Services Apartments</p>
        </Col>
        <Col lg={3} xs={6} className='travel-card'>
        <img
          className="d-block w-100"
          src={Corporate}
          alt="Corporate"
        />
        <p className='text-white'>Stays for Corporates daily, weekly monthly</p>
        </Col>
        <Col lg={3} xs={6} className='travel-card'>
        <img
        className="d-block w-100"
        src={Cinema}
        alt="Cinema"
      />
      <p className='text-white'>Cinema Room Concepts</p>
        </Col>
        <Col lg={3} xs={6} className='travel-card'>
        <img
        className="d-block w-100"
        src={Food}
        alt="Food"
      />
      <p className='text-white'>All Meals Included</p>
        </Col>
      </Row>
    </div>
    </div>
  );
}

export default Travellingblues;
