import React from 'react'
import { useState } from 'react';
import { Formik } from 'formik';
import { Row, Container, Col } from "react-bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';

const Form = ({onClose}) => {
  return (
    <div className='modal-container' onClick={(e)=>
    {if(e.target.className === "modal-container"){
      onClose()}
    }}
      >
      <div className='modals'>
        <div className='modal-header'>
          <h2>Enquire <span>Now</span></h2>
          <p>Simply your stay, amplify your experience </p>
          <p className='close' onClick={()=> onClose()}>&times;</p>
          <hr />
        </div>
        <div className='modal-content'>
        <Formik
      initialValues={{ fullname: '', email: '', phonenumber: '' }}
      validate={values => {
        const errors = {};
        if (!values.fullname) {
          errors.fullname = 'Required';
        } else if (!values.phonenumber) {
          errors.phonenumber = 'Required';
        }  
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          onClose()
          axios.post('https://api.propex.ai/web/live/CRMapi/PropertyCRM/LandingPageLeadInsertWithScheduleVisit', {
            Name: values.fullname,
            Email: values.email,
            Phone: values.phonenumber,
            LandingPageId: 38,
            leadSource: 'csuite lp',
            Mobile_Number_Validated: 'false',
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          }); 
          
          Swal.fire({
            title: "Thank you!",
            text: "Our Team will reach you shortly!!",
            icon: "success",
            showConfirmButton: false
          });
          //window.open('https://www.makemytrip.com/hotels/hotel-details/?hotelId=202306051534015306&_uCurrency=INR&checkin=06162023&checkout=06172023&city=CTBLR&country=IN&lat=12.96479&lng=77.70153&locusId=CTBLR&locusType=city&rank=1&regionNearByExp=3&roomStayQualifier=2e0e&searchText=Marathahalli%2C%20Bangalore&topHtlId=202306051534015306&mtkeys=defaultMtkey/', '_blank')
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        touched, 
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className='enquire-form'>
            <Row>
              <Col lg={12} md={12} sm={12} className="relative">
                <input
                  type="text"
                  name="fullname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fullname}
                  placeholder='Full Name'
                  className='w-100'
                />
                {errors.fullname && touched.fullname && <span className="error-text">{errors.fullname}</span>}
              </Col>

              <Col lg={12} md={12} sm={12} className="relative">
                <input
                  type="tel"
                  name="phonenumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phonenumber}
                  placeholder='Phone Number'
                  maxLength={10}
                  className='w-100'
                  
                />
                {errors.phonenumber && touched.phonenumber && <span className="error-text">{errors.phonenumber}</span>}
              </Col>

              <Col lg={12} md={12} sm={12} className="relative">
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder='E-mail Id'
                  className='w-100'
                />
                {errors.email && touched.email && <span className="error-text">{errors.email}</span>}
              </Col>

              <Col lg={12} md={12} sm={12} className="text-align-center">
                <button type="submit" disabled={isSubmitting} className='w-100 submit-btn'>
                  Submit
                </button>
                <input type="hidden" name="LandingPageId" id="LandingPageId" value="38"  />
              </Col>
            </Row>
        </form>      
      )}
      
    </Formik>
        </div>
      </div>
    </div>
  ) 
}

export default Form