import React from 'react';
import { useState } from 'react';
import wifi from "../Assets/images/Rooms/wifi.png"
import lotus from '../Assets/images/Rooms/rooms.jpg';
import santiago from '../Assets/images/Rooms/santiago.png';
import richfield from '../Assets/images/Rooms/richfield.png';
import marion from '../Assets/images/Rooms/marion.png';
import emerald from '../Assets/images/Rooms/emerald.png';
import grandcentral from '../Assets/images/Rooms/grand-central.png';
import royal from '../Assets/images/Rooms/royal.png';
import user from '../Assets/images/Rooms/user.png';
import prevArrow from '../Assets/images/Rooms/prevArrow.png';
import nextArrow from '../Assets/images/Rooms/nextArrow.png';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Form from './Form';
import Slider from "react-slick";
import "./css/style.css";
import "./css/modalform.css";

const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
  
    return (
      <div
        className={className}
        onClick={onClick}
      >
        <img src={prevArrow} alt="Previous" />
      </div>
    );
  }

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >
        <img src={nextArrow} alt="Next" />
      </div>
    );
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const rooms = [
    {
      name: "Lotus park",
      image: lotus
    },
    {
      name: "Santiago",
      image: santiago
    },
    {
      name: "Richfield",
      image: richfield
    },
    {
      name: "Marion",
      image: marion
    },
    {
      name: "Emerald",
      image: emerald
    },
    {
      name: "Vj Grand Central",
      image: grandcentral
    },
    {
      name: "Royal Homes",
      image: royal
    }
  ];

const Rooms = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: "0px",
        centerMode: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              dots: false,
              centerMode: true,
              centerPadding: "40px",
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              dots: false,
              centerMode: true,
              centerPadding: "40px",
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              centerMode: true,
              centerPadding: "10px",
              slidesToShow: 1.08,
            },
          },
        ],
      };

      const [modalOpen, setModalOpen] = useState(false)
      const handleButtonClick = () =>{
        setModalOpen(false);
      }
      
  return (
    <section className="Rooms section-white pt-0">
      <div className='rooms-wrap'>
        <Row>
          <Col lg={12}>
            <h3 className="text-center">Rooms</h3>
          </Col>
        </Row>

        <Slider {...settings} className="rooms-blues-slider py-lg-3">
          {rooms.map((room, index) => (
            <div key={index}>
              <Row className="rooms-blues-box">
                <Col lg={6} className="p-0">
                  <Image src={room.image} fluid className="room-image" />
                </Col>

                <Col lg={6} className="p-3">
                  <div className="rooms-blues-text">
                    <h4 className="text-left">{room.name}</h4>
                    <p><Image src={user} width="20px" height="20px" fluid className="" /> x 2</p>
                    <Image src={wifi} width="220px" height="30px" fluid className="amenity-icon" />
                    <button className="rooms-book-now" onClick={() =>setModalOpen(true)}><a className="" >Enquire Now</a></button>
                    
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </Slider>
      </div>
      
      {modalOpen && (<Form
      onClose={handleButtonClick}/>)}
    </section>
    
  );
}

export default Rooms;
