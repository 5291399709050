import React from 'react'
import { Link } from "react-router-dom";
import Whatsapp from '../Assets/images/whatsapp.png'
import Call from '../Assets/images/Call.png'

const Sidemenu = () => {
  const whatsappurl = () => {
    window.location.href = 'https://api.whatsapp.com/send?phone=918050065316&text=Hello,%20%20I%27m%20looking%20for%20C-suit%20room';
  }
  return (
    <>
    <div className='sidemenu-container' >
    <div className='whatsapp' onClick={whatsappurl}>
    <img src={Whatsapp} className='chat' width="40" height="40" alt="chat"/>
    </div>
    <a href="tel:8050065316"><div className='Call'>
   <img src={Call} className='call' width="35" height="35" alt="call"/>
    </div></a>
   
  </div>
   <div className='sidemenu-container-web d-flex d-md-none' >
   <div className='whatsapp d-flex' onClick={whatsappurl}>
   <img src={Whatsapp} className='chat' width="40" height="40" alt="chat"/> 
   <p>Whatsapp</p>
   </div>
   <a href="tel:8050065316"><div className='Call d-flex'>
  <img src={Call} className='call' width="35" height="35" alt="call"/>
  <p>Call us</p>
   </div></a>
  
 </div>
 </>
  )
}

export default Sidemenu