import React from 'react';
import { useState } from "react";
import Logo from "../Assets/images/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";

const Navbar = () => {

    const [openMenu, setOpenMenu] = useState(false)
    const menuOptions = [
        {
            text: "Home",
            icon: <HomeIcon/>
        },
        {
            text: "About",
            icon: <InfoIcon/>
        },
        {
            text: "Testimonials",
            icon: <CommentRoundedIcon/>
        },
        {
            text: "Contact",
            icon: <PhoneRoundedIcon/>
        },
        {
            text: "cart",
            icon: <ShoppingCartRoundedIcon/>
        }
    ]

  return (
    <nav className='logos'>
      <div className='nav-logo-container'>
        <img src={Logo} className='c-logo' alt="Logo"/>
      </div> 
    </nav>
  );
}

export default Navbar;
