import React from 'react';
import Slider from "react-slick";
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import cinemabanner1 from "../Assets/slider-img/world-cinema-1.png";
import cinemabanner2 from "../Assets/slider-img/world-cinema-2.png";
import cinemabanner3 from "../Assets/slider-img/world-cinema-3.png";
import cinemabanner4 from "../Assets/slider-img/world-cinema-4.png";
import cinemabanner5 from "../Assets/slider-img/world-cinema-5.png";
import cinemabanner6 from "../Assets/slider-img/world-cinema-6.png";

const Cinema = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerPadding: "20px",
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "10px",
          slidesToShow: 1,
        },
      },
    ],

  }
  return (
    <section className="worldcinema section-white">
            <Container>
                <Row>
                    <Col lg={12}>
                        <h3 className="text-center">Step into the world of Cinema</h3>
                    </Col>
                </Row>
            </Container>

            <Slider {...settings} className="world-cinema-slider py-lg-3">
                <div>
                    <Image  src={cinemabanner1} fluid className="" />
                </div>
                <div>
                    <Image  src={cinemabanner2} fluid className="" />
                </div>
                <div>
                    <Image  src={cinemabanner3} fluid className="" />
                </div>
                <div>
                    <Image  src={cinemabanner4} fluid className="" />
                </div>
                <div>
                    <Image  src={cinemabanner5} fluid className="" />
                </div>
                <div>
                    <Image  src={cinemabanner6} fluid className="" />
                </div>
             
            </Slider>

        </section>
  );
}

export default Cinema;
