import React from 'react';
import "react-image-gallery/styles/css/image-gallery.css"
import ImageGallery from "react-image-gallery";
import gallery from "../Assets/slider-img/gallery.png";
import gallery1 from "../Assets/slider-img/gallery1.png";
import gallery2 from "../Assets/slider-img/gallery2.png";
import gallery3 from "../Assets/slider-img/gallery3.png";
import gallery4 from "../Assets/slider-img/gallery4.png";
const images = [
  {
    original: gallery,
    thumbnail: gallery,
  },
  {
    original: gallery1,
    thumbnail: gallery1,
  },
  {
    original: gallery2,
    thumbnail: gallery2,
  },
  {
    original: gallery3,
    thumbnail: gallery3,
  },
  {
    original: gallery4,
    thumbnail: gallery4,
  },
];
const Gallery = () => {
  return (
    <div className='img-gallery'>
    <ImageGallery items={images}
    showPlayButton={false}
    showFullscreenButton={false}
    slideInterval={1000}

    />
    </div>
  );
}

export default Gallery;
