import React from 'react';
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const Footer = () => {
  return (
    <footer>
    <div className="footer">
      <Row>
        <Col lg={12} md={12} sm={12}>
              <div className="right-content">
                @ CSuites 2024
              </div>
        </Col>
      </Row>
    </div>
  </footer>
  );
}

export default Footer;
