
import './App.css';

import Travellingblues from './Components/Travellingblues';
import Navbar from './Components/Navbar';
import Cinema from './Components/Cinema';
import Gallery from './Components/Gallery';
import Banner2 from './Components/Banner2';
import Rooms from './Components/Rooms';
import Socialslider from './Components/Socialslider';
import Footer from './Components/Footer';
import Sidemenu from './Components/Sidemenu';

function App() {
  return (
    <div className="App">
    <Navbar />
     <Banner2 />
     <Sidemenu />
     <Travellingblues />
     <Cinema />
     <Rooms />
     <Gallery />
      <Socialslider />
      <Footer />
    </div>
  );
}

export default App;
