import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Carousel from 'react-bootstrap/Carousel';
import Banner1 from '../Assets/images/Herobanner1.png';
import Banner4 from '../Assets/images/Herobanner2.png';
import Banner3 from '../Assets/images/Herobanner3.png';
import MobBanner1 from '../Assets/images/MobBanner1.png';
import MobBanner2 from '../Assets/images/MobBanner2.png';
import MobBanner3 from '../Assets/images/MobBanner3.png';
import locIcon from '../Assets/images/locationicon.png';
import './css/component.css';

const Banner2 = () => {
  return (
    <div>
    <Carousel>
    <Carousel.Item>
    <img
    className=" w-100 d-mob-none"
    src={Banner1}
    alt="First slide"
  />
  <img
    className="d-xs-block w-100"
    src={MobBanner1}
    alt="First slide"
  />
      <Carousel.Caption>
        <h2>Unlock Unmatched Hospitality</h2>
        <p>Simplify your stay, amplify your experience</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
    <img
    className="w-100 d-mob-none"
    src={Banner4}
    alt="Second slide"
  />
   <img
    className="d-xs-block w-100"
    src={MobBanner3}
    alt="Second slide"
  />
      <Carousel.Caption>
        <h2>Unlock Unmatched Hospitality</h2>
        <p>Simplify your stay, amplify your experience</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
    <img
    className="w-100 d-mob-none"
    src={Banner3}
    alt="Third slide"
  />
  <img
    className="d-xs-block w-100"
    src={MobBanner2}
    alt="Third slide"
  />
      <Carousel.Caption>
        <h2>Unlock Unmatched Hospitality</h2>
        <p>Simplify your stay, amplify your experience</p>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
  <Container>
  <Tabs>
    <TabList>
      <Tab>All </Tab>
      <Tab>Bengaluru</Tab>
      <Tab>Pune</Tab>
    </TabList>

    <TabPanel>
    <Row>
       <Col lg={4} md={4} sm={12} onClick={event =>  window.location.href='https://be.aiosell.com/book/colive-lotus-park'} >
          <div className="d-flex csuitproperty">
             <img src={locIcon} className='property-icon' alt="location"/>
             <p>Colive Lotus Park, Marathahalli</p></div>
        </Col>
        <Col lg={4} md={4} sm={12} onClick={event =>  window.location.href='https://be.aiosell.com/book/colive-Richfield'}>
          <div className="d-flex csuitproperty">
             <img src={locIcon} className='property-icon' alt="location"/>
             <p>Colive Richfield, Silkboard </p></div>
        </Col>
        <Col lg={4} md={4} sm={12} onClick={event =>  window.location.href='https://be.aiosell.com/book/colive-VJ-Grand-Central'}>
          <div className="d-flex csuitproperty">
             <img src={locIcon} className='property-icon' alt="location"/>
             <p>VJ Grand Central, Pune</p></div>
        </Col>
      </Row>
    </TabPanel>
    <TabPanel>
    <Row>
       <Col lg={4} md={4} sm={12} onClick={event =>  window.location.href='https://be.aiosell.com/book/colive-lotus-park'}  >
          <div className="d-flex csuitproperty">
             <img src={locIcon} className='property-icon' alt="location"/>
             <p>Colive Lotus Park, Marathahalli</p></div>
        </Col>
        <Col lg={4} md={4} sm={12} onClick={event =>  window.location.href='https://be.aiosell.com/book/colive-Richfield'} >
          <div className="d-flex csuitproperty">
             <img src={locIcon} className='property-icon' alt="location"/>
             <p>Colive Richfield, Silkboard</p></div>
        </Col>
      </Row>
    </TabPanel>
    <TabPanel>
    <Row>
       <Col lg={4} md={4} sm={12} onClick={event =>  window.location.href='https://be.aiosell.com/book/colive-VJ-Grand-Central'} >
          <div className="d-flex csuitproperty">
             <img src={locIcon} className='property-icon' alt="location"/>
             <p>VJ Grand Central, Pune </p></div>
        </Col>
      </Row>
    </TabPanel>
  </Tabs>
  </Container>
    </div>
  );
}

export default Banner2;
